/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import './variable';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body{
    -webkit-font-smoothing: auto;
    font-family: 'Alegreya Sans';
  }
  
  .center-position{
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
  
  .container{
    max-width: 1425px;
    padding: 0px 90px;
  
    & > .row{
        padding-top: 100px !important;
        padding-bottom: 50px !important;
    }
  }
  
  .background-cover{
    background-repeat: no-repeat; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
  }
  
  .hidden{
    display: none;
  }
  
  /* Parallax base styles
    --------------------------------------------- */
  
    .parallax {
      height: 500px; /* fallback for older browsers */
      height: 100vh;
      width: 100vw;
      overflow-x: hidden;
      // overflow-y: auto;
      -webkit-perspective: 300px;
      perspective: 300px;
      font-size: 200%;
    }
  
    .parallax__group {
      position: relative;
      height: 500px; /* fallback for older browsers */
      // height: 100vh;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  
    .parallax__layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  
    .parallax__layer--fore {
      -webkit-transform: translateZ(90px) scale(.7);
      transform: translateZ(90px) scale(.7);
      z-index: 1;
    }
  
    .parallax__layer--base {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      z-index: 4;
    }
  
    .parallax__layer--back {
      -webkit-transform: translateZ(-300px) scale(2);
      transform: translateZ(-300px) scale(2);
      z-index: 3;
    }
  
    .parallax__layer--deep {
      -webkit-transform: translateZ(-600px) scale(3);
      transform: translateZ(-600px) scale(3);
      z-index: 2;
    }
  
     /* centre the content in the parallax layers */
  
     .title {
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: white;
      font-family: 'Alegreya Sans' !important;
      font-size: 46px;
      font-weight: 300;
    }
  
  
  // scroll bar here
  
  ::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(104, 81, 109, 0.82);
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #eed253;
  }
  